import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import ReactDOM from 'react-dom'
import './style.css'
import { Link } from 'react-router-dom'
import $ from 'jquery'


class Badge2 extends Component {
    constructor(props) {
        super(props);
        this.state = { col: 0 };

        this.close = this.close.bind(this);
        this.chImg = this.chImg.bind(this);
        this.chName = this.chName.bind(this);
        this.changebtn = this.changebtn.bind(this);
      
    }



    close() {
        this.setState({col : 0});
        $('img#i2').attr('src', '/imgs/buttonroundr.svg');
        $('img#i1').attr('src', '/imgs/buttonroundl.svg');
        $('#badge').fadeToggle();
        $('#hidemarket').show();
    }


    chImg() {
        if (this.props.id === '1') {
            return <img src="/imgs/logo1.svg"></img>
        }
        if (this.props.id === '2') {
            return <img src="/imgs/zaralogo.svg"></img>
        }
        if (this.props.id === '3') {
            return <img src="/imgs/ikea.svg"></img>
        }
        if (this.props.id === '4') {
            return <img src="/imgs/Framegp.svg"></img>
        }
        if (this.props.id === '5') {
            return <img src="/imgs/Framegb.svg"></img>
        }
        if (this.props.id === '6') {
            return <img src="/imgs/Framegs.svg"></img>
        }
    }
    chName() {
        if (this.props.id === '1') {
            return 'Voucher 10 €'
        }
        if (this.props.id === '2') {
            return 'Voucher 10 €'
        }
        if (this.props.id === '3') {
            return 'Voucher 10 €'
        }
        if (this.props.id === '4') {
            return 'Gift Pack'
        }
        if (this.props.id === '5') {
            return 'Gift Box'
        }
        if (this.props.id === '6') {
            return 'Gift Set'
        }
    }

    changebtn(e) {
        if (e.target.id === 'i1') {
            $('img#i2').attr('src', '/imgs/buttonroundr.svg');
            e.target.src = '/imgs/buttonroundlch.svg';
            if (this.state.col > 0) {
               this.setState({col: this.state.col - 1})
            }
            $('.addtb').attr('disabled', false);
        }
        if (e.target.id === 'i2') {
            $('img#i1').attr('src', '/imgs/buttonroundl.svg');
            e.target.src = '/imgs/buttonroundrch.svg';
            this.setState({col: this.state.col + 1})
            $('.addtb').attr('disabled', false);
        }
    }

  


    render() {

        return (
            <div style={{background: '#FFFFFF', minHeight: "100vh"}}>

                <div className="b2 col-11 m-auto">
                    {this.chImg()}
                    <p id="b-top-subtxt">{this.chName()}</p>
                </div>
                <p className="text col-11 m-auto">Tellus donec congue viverra in tellus augue varius. Arcu convallis at eget ac integer vestibulum, libero lorem</p>

                <div className="choose-col col-9 m-auto">
                    <img src="/imgs/buttonroundl.svg" onClick={this.changebtn} id='i1'></img>
                    <p>{this.state.col}</p>
                    <img src="/imgs/buttonroundr.svg" onClick={this.changebtn} id='i2'></img>
                </div>
                <div style={{ textAlign: "center", background: '#FFFFFF' }}>
                    <img style={{ margin: "14px auto auto auto",  background: '#FFFFFF' }} src="imgs/Frame38.svg"></img>
                </div>

                <div className="col-11 m-auto" style={{background: '#FFFFFF'}}>
                    <button className="addtb" onClick={() => { this.props.updateData(this.state.col); $('.addtb').attr('disabled', true)}}>Add to Basket</button>
                </div>





                <div style={{ justifyContent: "center", display: "flex", background: '#FFFFFF' }}>
                    <img style={{ position: "absolute", bottom: '0', background: "transparent" }} onClick={this.close} src="/imgs/close.png"></img>
                </div>

            </div>
        );
    }
}
export default Badge2

