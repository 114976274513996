import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './style.css'
import { Link } from 'react-router-dom'
import $ from 'jquery'


class Salesmeter extends Component {

    constructor(props) {
        super(props);

        this.change = this.change.bind(this);
        this.img1 = this.img1.bind(this);
        this.img2 = this.img2.bind(this);
        this.onWheel = this.onWheel.bind(this)
        this.onMouseDown = this.onMouseDown.bind(this)
        this.ondragstart= this.ondragstart.bind(this)

    }
    onWheel(event) {
        const delta = Math.max(-1, Math.min(1, (event.nativeEvent.wheelDelta || -event.nativeEvent.detail)))
        event.currentTarget.scrollLeft -= (delta * 10)
      
    };
    onMouseDown(event){
      
        event.preventDefault();
        let x = event.clientX;
        let el= event.currentTarget;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
  
        function onMouseMove(event) {

            const delta = Math.max(-1, Math.min(1, event.clientX - x))
            el.scrollLeft -= (delta * 10)
        }
  
        function onMouseUp() {
          document.removeEventListener('mouseup', onMouseUp);
          document.removeEventListener('mousemove', onMouseMove);
        }
  
      };
  
      ondragstart (e) {
        return false;
      };
       
    

    change(e) {

        $('.active')[0].className = $('.active')[0].className.replace(" active", "");

        e.target.className += " active";

        if (e.target.id === '1') {
            $('.sal').show();
            $('.awards').hide();
            $('.top5').hide();
        }
        if (e.target.id === '2') {
            $('.sal').hide();
            $('.awards').show();
            $('.top5').hide();
        }
        if (e.target.id === '3') {
            $('.sal').hide();
            $('.awards').hide();
            $('.top5').show();
        }

    }

    img1() {
       
        $('#img1').fadeToggle();
        $('#img2').delay(350).fadeToggle('slow')
    }
    img2() {
        $('#img2').fadeToggle();
        $('#img1').delay(350).fadeToggle('slow')
    }

    render() {
        return (
            <div style={{ background: '#F2F4F7', minHeight: "100vh" }}>
                <div className="img-inline col-11">
                    <div style={{ display: "inline-flex" }}>
                        <img style={{ marginRight: "10px" }} src="/imgs/star.svg"></img>
                        <img src="/imgs/2549.svg"></img>
                    </div>
                    <img src="/imgs/Ellipse26.svg" ></img>
                    <img src="/imgs/Group68.svg" ></img>
                </div>
                <div id="scroll" style={{ overflow: "auto" }} onWheel={this.onWheel} onMouseDown={this.onMouseDown} onDragStart={this.ondragstart}>
                    <img src="/imgs/frameinternal.svg"></img>
                </div>
                <ul className="navbar-nav col-10">
                    <li className="nav-item active" onClick={this.change} >
                        <a className="nav-link" href="#sal" id="1">Salesmeter</a>
                    </li>
                    <li className="nav-item" onClick={this.change} >
                        <a className="nav-link" href="#aw" id="2">Awards</a>
                    </li>
                    <li className="nav-item" onClick={this.change} >
                        <a className="nav-link" href="#t5" id="3">Top 5</a>
                    </li>
                </ul>
                <div className="sal col-10" >
                    <img id="img1" src="/imgs/Frame43.png" onClick={this.img1}></img>
                    <img id="img2" src="/imgs/Frame43_1.png" onClick={this.img2}></img>
                </div>
                <div className="menu_btm col-12">
                    <Link to="/salesmeter"><img id="imgs" src="/imgs/sales_cl.svg"></img></Link>
                    <Link to="/sales"><img id="imgs1" src="/imgs/sales2.svg"></img></Link>
                    <Link to="/market"><img id="imgs2" src="/imgs/sales1.svg"></img></Link>
                </div>

                <div className="awards" onWheel={this.onWheel} onMouseDown={this.onMouseDown} onDragStart={this.ondragstart}>
                    <img src="/imgs/Frame28.svg"></img>
                </div>
                <div className="top5" onWheel={this.onWheel} onMouseDown={this.onMouseDown} onDragStart={this.ondragstart}>
                    <img src="/imgs/Frame31.svg"></img>
                </div>


            </div>
        );
    }
}
export default Salesmeter


$('#img1').on('click', function(){
    $('#img1').fadeToggle();
    $('#img2').delay(350).fadeToggle('slow')
})




