import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from './components/App';
import Badge1 from './components/Badges/Badge1';
import Market from './components/Market';
import Registration from './components/Registration';
import Sales from './components/Sales';
import Salesmeter from './components/Salesmeter';



ReactDOM.render((

    <Router>
        <Route exact path="/" component={App} />
        <Route path="/login" component={Registration} />
        <Route path='/salesmeter' component={Salesmeter}/>
        <Route path='/sales' component={Sales}/>
        <Route path='/market' component={Market}/>
        <Route path="/badge"  component={Badge1}/>
       
    </Router>
),
    document.getElementById('rootIn'))