import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'

import './style.css'
import $ from 'jquery'


class Badge1 extends Component {
    constructor(props) {
        super(props);
        this.state = { date: new Date(new Date().getFullYear(), new Date().getMonth(), 1), colm: [], coly: [], colall: [] };
        this.change = this.change.bind(this);
        this.Month = this.Month.bind(this);
        this.close = this.close.bind(this);
        this.chImg = this.chImg.bind(this);
        this.chName = this.chName.bind(this);
    }


    componentDidUpdate(prevProps) {

        if (prevProps.id !== this.props.id) {
            this.getCols();
        }
    }
    componentWillMount() {
        this.getCols()

    }
    getCols() {
        this.setState({ colm: [] });
        this.setState({ coly: [] });
        this.setState({ colall: [] });

        this.setState({ date: new Date(new Date().getFullYear(), new Date().getMonth(), 1) });
        let d = this.state.date;
        let a = new Array(Number(this.props.col));
        for (let i = 0; i < a.length; i++) {
            a[i] = new Date(d.setDate(d.getDate() + 2));
        }
        this.setState({ colm: a });
      


        a = new Array(7 - Number(this.props.col));
        for (let i = 0; i < a.length; i++) {
            a[i] = new Date(d.setDate(d.getDate() + 2));
        }
        d = this.state.date;
        for (let i = 0; i < a.length; i++) {

            a[i] = new Date(d.setDate(d.getDate() - 7 * (new Date().getMonth())));

        }
        this.setState({ coly: a });

        a = new Array(19);
        for (let i = 0; i < a.length; i++) {

            a[i] = new Date(d.setDate(d.getDate() - 7 * (new Date().getMonth())));
        }
        this.setState({ colall: a });

    }


    close() {

        $('.active')[0].className = $('.active')[0].className.replace(" active", "");
        $('.nav-item')[0].className += ' active';
        $('.month').show();
        $('.year').hide();
        $('.all').hide();
        $('#num')[0].innerText = this.props.col;
        $('#proc')[0].innerText = '150%';
        $('#badge').fadeToggle();
        $('#hidesale').show();
    }

    change(e) {

        $('.active')[0].className = $('.active')[0].className.replace(" active", "");

        e.target.className += " active";

        if (e.target.id === '1') {
            $('.month').show();
            $('#num6').hide();
            $('.year').hide();
            $('.all').hide();
            $('#num')[0].innerText = this.props.col;
            $('#proc')[0].innerText = '150%';

        }
        if (e.target.id === '2') {
            $('.year').show();
            $('#num6').hide();
            $('.all').hide();
            $('#num')[0].innerText = '7';
            $('#proc')[0].innerText = '42%';

        }
        if (e.target.id === '3') {
            $('.all').show();
            $('#num')[0].innerText = '2';
            $('#num6').show();
            $('#proc')[0].innerText = '';
        }

    }

    Month() {

        let m = new Date().getMonth();

        if (m < 9) {
            return "0" + (m + 1);
        }
        else {
            return (m + 1)
        }
    }
    chImg() {
        if (this.props.car === '1') {
            return <img alt="" src="/imgs/component14.png"></img>
        }
        if (this.props.car === '2') {
            return <img alt="" src="/imgs/Property12.png"></img>
        }
        if (this.props.car === '3') {
            return <img alt="" src="/imgs/Property13.png"></img>
        }
        if (this.props.car === '4') {
            return <img alt="" src="/imgs/Property14.png"></img>
        }
        if (this.props.car === '5') {
            return <img alt="" src="/imgs/Property15.png"></img>
        }
    }
    chName() {
        if (this.props.car === '1') {
            return 'Smart'
        }
        if (this.props.car === '2') {
            return 'Sedan'
        }
        if (this.props.car === '3') {
            return 'SUV'
        }
        if (this.props.car === '4') {
            return '4WD'
        }
        if (this.props.car === '5') {
            return 'Van'
        }
    }


    render() {

        return (
            <div id="badge1" style={{ background: '#FFFFFF', minHeight: '100vh', borderTopLeftRadius: '24px', borderTopRightRadius:'24px' }}>
                <div style={{ overflow: "hidden" }}>
                    <div className="b-top">
                        <div style={{display:'flex', margin:"auto 0", justifyContent:"center"}}>
                             <p id='num'>{this.props.col}</p>
                             <p id='num6'>6</p>
                        </div>
                       
                        <div style={{ display: "block", textAlign: "center" }}>
                            {this.chImg()}
                            <p id="b-top-subtxt">{this.chName()}</p>
                        </div>
                        <p id='proc'>150%</p>
                    </div>

                    <ul className="navbar-nav col-10">
                        <li className="nav-item active" onClick={this.change} >
                            <a className="nav-link" href="#m" id="1">Month ({this.props.col})</a>
                        </li>
                        <li className="nav-item" onClick={this.change} >
                            <a className="nav-link" href="#y" id="2">Year (7)</a>
                        </li>
                        <li className="nav-item" onClick={this.change} >
                            <a className="nav-link" href="#all" id="3">All (26)</a>
                        </li>
                    </ul>
                </div>


                <div className="tables">
                    <table className="month col-11">
                        <tbody>
                            {
                                this.state.colm.map((date, i) => {

                                    let day = (date.getDate() < 10) ? "0" + date.getDate() : date.getDate();
                                    let m = (date.getMonth() < 9) ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
                                    let y = date.getFullYear();

                                    return <tr key={i}>
                                        <td style={{ width: "50%" }}>
                                            <p>{day}.{m}.{y}</p>
                                        </td>
                                        <td style={{ width: "25%" }}>
                                            {(this.props.id > 5 && this.props.id < 9) ? <img alt="" src="/imgs/Group71500.svg"></img> : <img alt="" src="/imgs/Group71.svg"></img>}
                                        </td>
                                        <td style={{ width: "25%" }}>
                                            {(this.props.id > 5 && this.props.id < 9) ? <img alt="" src="/imgs/Group70400.svg"></img> : <img alt="" src="/imgs/Group70.svg"></img>}

                                        </td>
                                    </tr>

                                }, this)
                            }
                        </tbody>

                    </table>

                    <table className="year col-11">
                        <tbody>
                            {

                                this.state.coly.map((date, i) => {

                                    if (this.Month === 1) {

                                    }
                                    else {

                                        let day = (date.getDate() < 10) ? "0" + date.getDate() : date.getDate();
                                        let m = (date.getMonth() < 9) ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
                                        let y = date.getFullYear();

                                        return <tr key={i}>
                                            <td style={{ width: "50%" }}>
                                                <p>{day}.{m}.{y}</p>
                                            </td>
                                            <td style={{ width: "25%" }}>
                                                {(this.props.id > 5 && this.props.id < 9) ? <img alt="" src="/imgs/Group71500.svg"></img> : <img alt="" src="/imgs/Group71.svg"></img>}
                                            </td>
                                            <td style={{ width: "25%" }}>
                                                {(this.props.id > 5 && this.props.id < 9) ? <img alt="" src="/imgs/Group70400.svg"></img> : <img alt="" src="/imgs/Group70.svg"></img>}

                                            </td>
                                        </tr>;
                                    }


                                }, this)

                            }
                        </tbody>

                    </table>

                    <table className="all col-11">
                        <tbody>
                            {
                                this.state.colall.map((date, i) => {

                                    let day = (date.getDate() < 10) ? "0" + date.getDate() : date.getDate();
                                    let m = (date.getMonth() < 9) ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
                                    let y = date.getFullYear();

                                    return <tr key={i}>
                                        <td style={{ width: "50%" }}>
                                            <p>{day}.{m}.{y}</p>
                                        </td>
                                        <td style={{ width: "25%" }}>
                                            {(this.props.id > 5 && this.props.id < 9) ? <img alt="" src="/imgs/Group71500.svg"></img> : <img alt="" src="/imgs/Group71.svg"></img>}
                                        </td>
                                        <td style={{ width: "25%" }}>
                                            {(this.props.id > 5 && this.props.id < 9) ? <img alt="" src="/imgs/Group70400.svg"></img> : <img alt="" src="/imgs/Group70.svg"></img>}

                                        </td>
                                    </tr>;



                                }, this)

                            }
                        </tbody>

                    </table>

                </div>

                <div style={{ justifyContent: "center", display: "flex", background: '#FFFFFF' }}>
                    <img style={{ position: "absolute", bottom: '0', background: "transparent" }} onClick={this.close} src="/imgs/close.png"></img>
                </div>

            </div>
        );
    }
}
export default Badge1

