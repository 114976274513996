import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import '../components/App.css'
import Registration from './Registration'
import $ from 'jquery'



class App extends Component {
    state = {
        open: false
    }
    componentWillUnmount(){
        $("#scroll").animate({ scrollLeft: '140vw' }, 10);
    }


    render() {
        return (
            <div>

                <div id="init" style={{ display: "grid",height: "100vh", background: "#E34415" }}>
                    <p className="h1">Boost<br></br> your sales<br></br>
                    <span className="sub-h1">in the right direction</span></p>
                    <img className="img-m" src="/imgs/image9.png"></img>
                    <div style={{display: "inline-flex", justifyContent: "center",  background: "#E34415"}}>
                         <button id="slide"  className="col-10">Get Started</button>
                    </div>
                   
                </div>
            
                    <div id="show" style={{height: '100vh'}}>
                        <Registration></Registration>
                    </div>

                
            </div>

        );
    }
}
export default App

$(document).ready(function(){
    $("#scroll").animate({ scrollLeft: '405px' }, 10);
    $("#slide").click(function () {
        $('.h1').fadeOut('slow')
        $('.sub-h1').fadeOut('slow')
        $('#img-m').slideUp('slow');
        $('#init').slideUp('slow')
        $('#show').delay(200).fadeIn('slow');
    
    })
})
