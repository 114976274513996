import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.css'
import './style.css'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import Badge1 from '../Badges/Badge1'


class Sales extends Component {


    constructor(props) {
        super(props);
        this.state = { col: "", id: "", show: "false", car: "" };

        this.onChoose = this.onChoose.bind(this);
        this.showBadge = this.showBadge.bind(this);
        this.onWheel = this.onWheel.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this)
        this.ondragstart= this.ondragstart.bind(this)

    }
    onWheel(event) {
        const delta = Math.max(-1, Math.min(1, (event.nativeEvent.wheelDelta || -event.nativeEvent.detail)))
        event.currentTarget.scrollLeft -= (delta * 10)
      
    };
    onMouseDown(event){
      
        event.preventDefault();
        let x = event.clientX;
        let el= event.currentTarget;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
  
        function onMouseMove(event) {

            const delta = Math.max(-1, Math.min(1, event.clientX - x))
            el.scrollLeft -= (delta * 10)
        }
  
        function onMouseUp() {
          document.removeEventListener('mouseup', onMouseUp);
          document.removeEventListener('mousemove', onMouseMove);
        }
  
      };
  
      ondragstart (e) {
        return false;
      };
       
    


    onChoose(e) {
        $('#badge').fadeToggle();

        $('#hidesale').hide();
        if (e.target.id === '1') {
            this.setState({ col: '3', car: "1", id: '1' });

        }
        if (e.target.id === '2') {
            this.setState({ col: '0', car: "2", id: '2' })
        }
        if (e.target.id === '3') {
            this.setState({ col: '1', car: "3", id: '3' })
        }
        if (e.target.id === '4') {
            this.setState({ col: '1', car: "4", id: '4' })
        }
        if (e.target.id === '5') {
            this.setState({ col: '2', car: "5", id: '5' })
        }
        if (e.target.id === '6') {
            this.setState({ col: '1', car: "2", id: '6' })
        }
        if (e.target.id === '7') {
            this.setState({ col: '0', car: "3", id: '7' })
        }
        if (e.target.id === '8') {
            this.setState({ col: '1', car: "5", id: '8' })
        }
        this.setState({ show: "true" });

    }

    showBadge() {
        if (this.state.show === "true") {
            return <Badge1 col={this.state.col} id={this.state.id} car={this.state.car} />

        }


    }

    componentWillUnmount() {
        $("#scroll").animate({ scrollLeft: '405vw' }, 1);

    }


    render() {

        return (
            <div>
                <div id="hidesale">
                    <p className="category col-11 m-auto" >Cars</p>

                    <div className="cars col-12" onWheel={this.onWheel} onMouseDown={this.onMouseDown} onDragStart={this.ondragstart} >
                        <img src="/imgs/badge.png" id='1' onClick={this.onChoose}></img>
                        <img src="/imgs/badge1.svg" id='2' onClick={this.onChoose}></img>
                        <img src="/imgs/badge2.png" id='3' onClick={this.onChoose}></img>
                        <img style={{ marginLeft: "-6px" }} src="/imgs/tovar53.svg" id='4' onClick={this.onChoose}></img>
                        <img style={{ marginLeft: "-15px" }} src="/imgs/tovar54.png" id='5' onClick={this.onChoose}></img>

                    </div>


                    <p className="category col-11 m-auto" style={{ marginTop: "4.9vh !important" }}>Service</p>
                    <div className="serv col-11" onWheel={this.onWheel} onMouseDown={this.onMouseDown} onDragStart={this.ondragstart}>
                        <img src="/imgs/badge21.svg" id='6' onClick={this.onChoose}></img>
                        <img src="/imgs/badge22.svg" id='7' onClick={this.onChoose}></img>
                        <img src="/imgs/badge23.svg" id='8' onClick={this.onChoose}></img>
                    </div>

                    <div className="menu_btm col-12">
                        <Link to="/salesmeter" refresh='true' ><img id="imgs" src="/imgs/sales.svg"></img></Link>
                        <Link to="/sales"><img id="imgs1" src="/imgs/sales1_cl.svg"></img></Link>
                        <Link to="/market"><img id="imgs2" src="/imgs/sales1.svg"></img></Link>

                    </div>

                </div>
                <div id="badge" style={{ display: "none", background: '#FFFFFF' }}>
                    {this.showBadge()}
                </div>

            </div>
        );
    }
}
export default Sales

